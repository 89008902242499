import { Message } from 'element-ui'


function success(msg) {
    Message({
        message: msg || '成功',
        type: 'success',
        duration: 5 * 1000
      })
}


function fail(msg) {
    Message({
        message: msg || '失败',
        type: 'error',
        duration: 5 * 1000
    })
}

export default {
    success,
    fail
}