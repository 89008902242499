import axios from 'axios'
import router from '../../router'

const state = () => ({
    authInfo: null
})

// getters
const getters = {

}

// actions
const actions = {
    loadAuthInfo({ commit }) {
        axios.get("/auth/info").then((data) => {
            commit('setAuthInfo', data)
        });
    },

    login({ commit }, loginInfo) {
        axios.post("/auth/login", loginInfo).then((data) => {
            router.push("/info");
        });
    },

    logout({ commit }) {
        axios.post("/auth/logout").then((data) => {
            router.push("/");
        });
    },

    checkLogin({ commit }) {
        axios.post("/auth/check").then((resp) => {
            commit('setAuthInfo', null)
            router.push("/");
        });
    },
}

// mutations
const mutations = {
    setAuthInfo(state, authInfo) {
        state.authInfo = authInfo;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}