const routes = [
  {
    path: "/",
    inNav: false,
    name: "vielott-管理系统",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/auth",
    inNav: false,
    name: "登陆信息",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/info",
        name: "",
        component: () => import("../views/auth/Info.vue"),
      },
    ],
  },
  {
    path: "/sys",
    name: "系统管理",
    icon: "el-icon-setting",
    inNav: true,
    permission: "SYS",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/su-list",
        name: "管理员列表",
        component: () => import("../views/sys/List.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "用户管理",
    icon: "el-icon-s-custom",
    inNav: true,
    permission: "USER",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/user-list",
        name: "用户列表",
        component: () => import("../views/user/List.vue"),
      },
      {
        path: "/fr-list",
        name: "资金记录",
        component: () => import("../views/fr/List.vue"),
      },
    ],
  },

  {
    path: "/trade",
    name: "交易管理",
    icon: "el-icon-money",
    inNav: true,
    permission: "TRADE",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/ro-list",
        name: "充值",
        component: () => import("../views/ro/List.vue"),
      },
      {
        path: "/wdo-list",
        name: "提款",
        component: () => import("../views/wdo/list.vue"),
      },
      {
        path: "/ra-list",
        name: "收款账户",
        component: () => import("../views/ra/List.vue"),
      },

      {
        path: "/to-list",
        name: "转账",
        component: () => import("../views/to/List.vue"),
      },
    ],
  },

  {
    path: "/period",
    name: "期号管理",
    icon: "el-icon-files",
    inNav: true,
    permission: "USER",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/period-list",
        name: "列表",
        component: () => import("../views/period/List.vue"),
      },
    ],
  },
  {
    path: "/bi",
    name: "投注管理",
    icon: "el-icon-s-order",
    inNav: true,
    permission: "BI",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/bi-list",
        name: "投注列表",
        component: () => import("../views/bi/List.vue"),
      },
      {
        path: "/rp-list",
        name: "追号计划",
        component: () => import("../views/rp/List.vue"),
      },
    ],
  },
  {
    path: "/news",
    name: "资讯管理",
    inNav: true,
    icon: "el-icon-notebook-1",
    permission: "NEWS",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/news-list",
        name: "列表",
        component: () => import("../views/news/List.vue"),
      },
      {
        path: "/news-edit",
        name: "新建/编辑",
        component: () => import("../views/news/Edit.vue"),
      },
    ],
  },
  {
    path: "/proxyopr",
    name: "代理操作",
    inNav: true,
    icon: "el-icon-notebook-1",
    permission: "PROXYOPR",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/proxy-fr-list",
        name: "代理资金明细",
        component: () => import("../views/proxyopr/FrList.vue"),
      },
      {
        path: "/proxy-user-list",
        name: "下线用户列表",
        component: () => import("../views/proxyopr/List.vue"),
      },
      {
        path: "/proxy-userfr-list",
        name: "下线用户资金",
        component: () => import("../views/proxyopr/UserFrList.vue"),
      },
    ],
  },
];

export default routes;
