import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'

import msg from './util/msg.js'

Vue.config.productionTip = false

axios.defaults.baseURL = "/api"
axios.interceptors.response.use((response) => {

  const res = response.data
  const status = response.status;

  if (status == 203) {
    msg.fail('未登录');
    router.push("/");
    return;
  }
  if (status == 401) {
    msg.fail('无权限');
    return;
  }

  if (!res.success) {
    msg.fail(res.msg);

    return Promise.reject(new Error(res.message || 'Error'))
  } else {
    // console.log(res.data)
    return res.data
  }
}, (err) => {
  console.log('err' + err) // for debug
  msg.fail(err.message);
  return Promise.reject(err)
})

Vue.use(VueAxios, axios)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.title = to.name
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
